.img {
  cursor: pointer;
  transition: 0.5s;
  width: 100%;
  height: auto;
}
.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* transition:0.2s; */
  height: auto;
  width: 74vh;
  margin-left: -340px;
}
.imgWrapper {
  flex-direction: row;
  display: flex;
  width: 100%;
}

.imgWrapperZoomIn {
  flex-direction: row;
  display: flex;
  width: 150%;
}

.scrollView {
  width: calc(100% - 120px);
  height: auto;
  display: flex;
  align-self: center;
  flex-direction: column;
}
.imgScrollView {
  width: 100%;
  height: auto;
}
@media (max-height: 920px) {
  .container {
    width: 76vh;
  }
}

@media only screen and (min-width: 1570px) {
  .imgWrapperZoomIn {
    width: 150%;
  }
}
@media only screen and (max-width: 1569px) {
  .imgWrapperZoomIn {
    width: 130%;
  }

  .container {
    margin-left: -180px;
  }
}
